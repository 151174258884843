import styled from 'styled-components';

const SectionWrapper = styled.section`
  /* background: linear-gradient(to bottom, #ffffff 0%, #f3f6f8 50%, #f3f6f8 100%); */

  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('https://images.unsplash.com/photo-1581368163672-d717bcb4c6af?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&h=900&q=80');

  padding: 50px 0 100px 0;
  margin: 60px 0 60px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #edcd37;
  }
`;

export default SectionWrapper;
