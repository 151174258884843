import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
// import SectionWrapper from './service.style';
import {FaqWrapper} from './faq.style'

const FaqSection = ({
  secTitleWrapper,
  secText,
  secHeading,
  Row,
  Col,
  serviceTitleStyle,
  secDes,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasClassicJson {
        FAQ_DATA {
          title
          description
        }
      }
    }
  `);

  return (
    <FaqWrapper id="questions_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Heading
            {...secHeading}
            content="We make getting seen easier"
          />
          <Text
            {...secDes}
            content="With tools and services designed for podcasters"
            />
          {/* <Text {...secText} content="With tools and services designed for podcasters" /> */}
        </Box>

        <Box {...Row}>
          {Data.saasClassicJson.FAQ_DATA.map((item, index) => (
            <Box
              {...Col}
              key={`faq-item-${index}`}
              className="faq_item_col"
            >
              <Box className="faq_item">
                <Box className="faq_icon">
                  {/* <i className={item.icon} /> */}
                  <Heading as="h3" content={item.title} {...serviceTitleStyle} />
                </Box>
                <Box className="faq_description">
                  <p>{item.description}</p>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </FaqWrapper>
  );
};

FaqSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  Row: PropTypes.object,
  Col: PropTypes.object,
  serviceTitleStyle: PropTypes.object,
  secDes: PropTypes.object,
};

FaqSection.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '80px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    // fontSize: ['20px', '24px', '36px', '36px'],
    fontSize: ['24px', '36px', '48px', '48px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '540px',
    maxWidth: '100%',
  },
  Row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    ml: ['0', '-15px', '-20px', '-35px', '-35px'],
    mr: ['0', '-15px', '-20px', '-35px', '-35px'],
  },
  Col: {
    pl: ['15px', '15px', '25x', '35px', '35px'],
    pr: ['15px', '15px', '25px', '35px', '35px'],
    mb: '40px',
  },
  serviceTitleStyle: {
    // fontSize: ['15px', '15px', '15px', '16px', '17px'],
    fontSize: ['20px', '20px', '20px', '21px', '22px'],
    fontWeight: '500',
    color: '#ff4361',
    mb: 0,
  },
  secDes: {
    fontSize: ['18px'],
    // fontSize: ['14px', '15px', '16px'],
    color: '#5d646d',
    lineHeight: '1.875',
    mt: '30px',
    ml: 'auto',
    mr: 'auto',
    width: '470px',
    maxWidth: '100%',
    textAlign: 'center',
  },
};

export default FaqSection;
